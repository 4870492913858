import React from "react";
import SingleBrand from "./SingleBrand";
import brandData from "./brandData";

const Brands = () => {
  return (
    <section className="border border-x-0 border-y-stroke bg-gray-100 py-11 dark:border-y-strokedark ">
    </section>
  );
};

export default Brands;
