import { FaTelegram, FaEnvelope, FaHeadset } from 'react-icons/fa';
import { FiArrowRight } from 'react-icons/fi';

const ProxyHero = () => {
  
};

const handleCrispChat = () => {
  if (window.$crisp) {
    window.$crisp.push(["do", "chat:open"]);
  } else {
    console.warn("Crisp chat is not initialized yet.");
  }
};

// Social Link Component
const SocialLink = ({ icon, text, href }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="group bg-white p-5 rounded-xl flex items-center gap-4 border border-gray-300 hover:border-purple-400 shadow-sm hover:shadow-md transition duration-200"
  >
    <div className="bg-purple-200 p-2 rounded-lg text-black">
      {icon}
    </div>
    <span className="text-sm md:text-base font-medium text-gray-800 flex-grow">
      {text}
    </span>
    <FiArrowRight className="text-gray-500 group-hover:text-purple-600 transition-colors" />
  </a>
);

export default ProxyHero;